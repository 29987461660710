<template>
<!--  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="参数名称" prop="configName">
        <a-input v-model="form.configName" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="参数分组" prop="configGroup">
        <a-input v-model="form.configGroup" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="参数键名" prop="configKey">
        <a-input v-model="form.configKey" placeholder="请输入" :readOnly="formty" :disabled="readOnly"/>
      </a-form-model-item>
      <a-form-model-item label="参数类型" prop="configType">
        <a-radio-group v-model="form.configType" button-style="solid">
          <a-radio-button v-for="(d, index) in  configTypeOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否多语言" prop="configLocale" v-if="form.configType === '3'">
        <a-radio-group v-model="form.configLocale" button-style="solid">
          <a-radio-button v-for="(d, index) in typeOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="参数键值" v-if="form.configType === '2'" prop="configValue">
        <a-textarea v-model="form.configValue" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="参数键值" v-if="form.configType === '3'" prop="configValue">
        <editor v-model="form.configValue"/>
      </a-form-model-item>
      <a-form-model-item label="参数键值" v-if="form.configType === '4'" prop="configValue">
        &lt;!&ndash;  图片上传      &ndash;&gt;
        <a-upload
          name="configValue"
          listType="picture-card"
          class="avatar-uploader"
          :multiple="false"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverImgUpload">
          <img
            v-if="form.configValue"
            :src="form.configValue"
            alt="configValue"
            style="height: 102px; width: 102px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>


      <a-form-model-item label="系统内置" prop="configSystem">
        <a-radio-group v-model="form.configSystem" button-style="solid">
          <a-radio-button v-for="(d, index) in typeOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入备注" type="textarea" allow-clear/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>-->
  <page-header-wrapper  @back="back" >
    <template v-slot:breadcrumb>
      {{ formTitle }}
    </template>
    <template v-slot:title>
      {{ formTitle }}
    </template>
    <a-divider></a-divider>
    <div>
      <a-space>
        <a></a><a></a>
        <a-button type="primary" :loading="submitLoading" @click="submitForm">
          {{ $t('通用.按钮.保存') }}
        </a-button>
        <a-button type="dashed" @click="cancel">
          {{ $t('通用.按钮.取消') }}
        </a-button>
      </a-space>
    </div>
    <a-divider></a-divider>
    <div class="bg-white padding-lr" ref="container">
      <a-form-model ref="form" :model="form" :rules="rules" labelAlign="left">
        <a-form-model-item label="参数名称" prop="configName">
          <a-input v-model="form.configName" placeholder="请输入"/>
        </a-form-model-item>
        <a-form-model-item label="参数分组" prop="configGroup">
          <a-input v-model="form.configGroup" placeholder="请输入"/>
        </a-form-model-item>
        <a-form-model-item label="参数键名" prop="configKey">
          <a-input v-model="form.configKey" placeholder="请输入" :readOnly="formty" :disabled="readOnly"/>
        </a-form-model-item>
        <a-form-model-item label="参数类型" prop="configType"  v-if="form.configType !== '2' && form.configType !== '3'&&form.configType !== '4'">
          <a-radio-group v-model="form.configType" button-style="solid">
            <a-radio-button v-for="(d, index) in  configTypeOptions" :key="index" :value="d.value">
              {{ d.label }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label="参数类型" prop="configType" v-if="form.configType === '2'">
          <a-radio-group v-model="form.configType" button-style="solid">
            <a-radio-button v-for="(d, index) in  configTypeOptions2" :key="index" :value="d.value">
              {{ d.label }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label="参数类型" prop="configType"v-if="form.configType === '3'">
          <a-radio-group v-model="form.configType" button-style="solid">
            <a-radio-button v-for="(d, index) in  configTypeOptions3" :key="index" :value="d.value">
              {{ d.label }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label="参数类型" prop="configType"v-if="form.configType === '4'">
          <a-radio-group v-model="form.configType" button-style="solid">
            <a-radio-button v-for="(d, index) in  configTypeOptions4" :key="index" :value="d.value">
              {{ d.label }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
<!--        <a-form-model-item label="是否多语言" prop="configLocale" v-if="form.configType === '3'">
          <a-radio-group v-model="form.configLocale" button-style="solid">
            <a-radio-button v-for="(d, index) in typeOptions" :key="index" :value="d.value">
              {{ d.label }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>-->

        <a-form-model-item label="参数描述" v-if="form.configType === '2'" prop="configValue">
          <a-textarea v-model="form.configValue" placeholder="请输入"/>
        </a-form-model-item>
        <a-form-model-item label="参数键值" v-if="form.configType === '3'" prop="configValue">
          <editor v-model="form.configValue"/>
        </a-form-model-item>
        <a-form-model-item label="参数键值" v-if="form.configType === '4'" prop="configValue">
          <OssUploadSingle v-model="form.configValue" type="img" :maxSize="200"></OssUploadSingle>
          <span>上传图片为.jpg.png.bmp格式，数量至少为1，尺寸800*800px</span>
        </a-form-model-item>
<!--        <a-form-model-item label="参数键值" v-if="form.configType === '4'" prop="configValue">
          &lt;!&ndash;  图片上传      &ndash;&gt;
          <a-upload
            name="configValue"
            listType="picture-card"
            class="avatar-uploader"
            :multiple="false"
            :show-upload-list="false"
            :preview="handlePreview"
            :before-upload="beforeOssUpload"
            :customRequest="coverImgUpload">
            <img
              v-if="form.configValue"
              :src="form.configValue"
              alt="configValue"
              style="height: 102px; width: 102px;"/>
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>-->


<!--        <a-form-model-item label="系统内置" prop="configSystem">
          <a-radio-group v-model="form.configSystem" button-style="solid">
            <a-radio-button v-for="(d, index) in typeOptions" :key="index" :value="d.value">
              {{ d.label }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>-->
        <a-form-model-item label="备注" prop="remark">
          <a-input v-model="form.remark" placeholder="请输入备注" type="textarea" allow-clear/>
        </a-form-model-item>
        <a-divider></a-divider>
        <div class="bottom-control">
          <a-space>
            <a-button type="primary" :loading="submitLoading" @click="submitForm">
              保存
            </a-button>
            <a-button type="dashed" @click="cancel">
              取消
            </a-button>
          </a-space>
        </div>
      </a-form-model>
    </div>
  </page-header-wrapper>
</template>

<script>

import {getConfig, addConfig, updateConfig} from '@/api/system/config'
import Editor from '@/components/Editor'
import {uploadObject, uploadOss} from "@/api/tool/oss";
import events from "@/components/MultiTab/events";
import bus from "@/utils/bus";
import {getShop} from "@/api/shop/shop";
import OssUploadSingle from "@/components/OssUploadSingle";
// import OssUploadMulti from '@/views/goods/goods/modules/OssMultiUpload.vue';
import OssUploadMulti from "@/components/OssUploadMulti"
export default {
  name: 'CreateForm',
  props: {
    typeOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    Editor,OssUploadSingle, OssUploadMulti,
  },
  data() {
    return {
      loading: null,
      submitLoading: false,
      formTitle: '',
      configTypeOptions: [{"value": "2", "label": "文本"}, {"value": "3", "label": "富文本"}, {"value": "4", "label": "图片"}],
      configTypeOptions2: [{"value":"2","label":"文本"}],
      configTypeOptions3: [{"value":"3","label":"富文本"}],
      configTypeOptions4: [{"value":"4","label":"图片"}],
      // 表单参数
      form: {
        id: undefined,
        configName: undefined,
        configGroup: undefined,
        configKey: undefined,
        configValue: "",
        configSystem: 'Y',
        configLocale: 'N',
        configType: "1",
        remark: undefined
      },
      readOnly: false,
      open: false,
      rules: {
        configName: [{required: true, message: '参数名称不能为空', trigger: 'blur'}],
        configGroup: [{required: true, message: '参数名称不能为空', trigger: 'blur'}],
        configKey: [{required: true, message: '参数键名不能为空', trigger: 'blur'}],
        configValue: [{required: true, message: '参数键值不能为空', trigger: 'blur'}]
      }
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.handleUpdate(this.form.id)
    } else {
      this.handleAdd()
    }
  },
  methods: {
    container() {
      return this.$refs.container;
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
      this.back()
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        configName: undefined,
        configGroup: undefined,
        configKey: undefined,
        configValue: '',
        configSystem: 'Y',
        configLocale: 'N',
        configType: "1",
        remark: undefined
      }
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.readOnly = false
      this.open = true
      this.formTitle = '添加参数'
    },
    /** 修改按钮操作 */
    handleUpdate(id) {
      this.reset()
      // this.readOnly = true
      // const configId = row ? row.id : ids
      getConfig(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改参数'
      })
    },
    /*/!** 修改按钮操作 *!/
    handleUpdate(id) {
      this.reset()
      this.formType = 2
      debugger
      getShop({"id": id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
        //回显出分类
        this.categoryList.forEach(e => {
          if (e.children) {
            e.children.forEach(child => {
              if (child.id === this.form.categoryId) {
                this.categorySelected.push(e.id)
                this.categorySelected.push(child.id)
              }
            });
          }
        });
      })
    },*/
    // oss 上传开始
    async handlePreview(file) {
      let suffix = file.name.substring(file.name.lastIndexOf('.'));
      if (suffix === '.mp4') {
        this.handleVideoPreview(file);
      } else {
        this.handleImagePreview(file);
      }
    },

    async handleVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewVideoVisible = true
    },
    async handleImagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewImageVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'config'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'configValue', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    // oss 上传结束
    back() {
      let pages = this.$store.pages;
      pages.indexOf(this.$route)
      events.$emit('close', pages.indexOf(this.$route))
      // 去更新列表页的数据
      bus.$emit('updateParameterList')
      this.$router.push({path: '/system/config', replace: true, query:{}  })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined) {
            updateConfig(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addConfig(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
