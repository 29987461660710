<template>
  <div>
    <a-modal
      :title="mode[titleName]"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      :footer="null"
    >
      <div class="content">
        <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" :rules="rules">
          <a-form-model-item label="适用性别" prop="gender" style="width: 100%">
            <a-select v-model="form.gender" placeholder="请选择适用性别" allowClear>
              <a-select-option :value="0"> 男性 </a-select-option>

              <a-select-option :value="1"> 女性 </a-select-option>

              <a-select-option :value="2"> 通用 </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="指数名称" prop="indexName">
            <a-input v-model="form.indexName" :maxLength="16" placeholder="请输入指数名称" />
          </a-form-model-item>

          <a-form-model-item label="设定范围(%)" prop="categoryName">
            <div class="listNum" style="width: 100%">
              <a-input v-model="form.maxPercentage" :maxLength="16" style="width: 150px" />
              <a-input v-model="form.minPercentage" :maxLength="16" style="width: 150px" />
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>

      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button @click="handleCancel">取 消</a-button>
        <a-button type="primary" :loading="loading" @click="submit" class="submitClass">保 存</a-button>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { createGoods , updateGoods , getDeviceEdit} from '@/api/goods/goodsIndex'
export default {
  data() {
    return {
      loading: false,
      mode: {
        add: '新增',
        edit: '编辑',
      },
      // 表单参数
      form: {
        id: null,
        gender: undefined,
        indexName: '',
        maxPercentage: '',
        minPercentage: '',
      },
      rules: {
        indexName: [{ required: true, message: '指数名称不能为空!', trigger: 'blur' }],
        gender: [{ required: true, message: '适用性别不能为空!', trigger: 'change' }],
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      titleName: 'add',
      confirmLoading: false,
      visible: false,
    }
  },
  methods: {
    open(item) {
      this.titleName = item
      this.visible = true
    },
    // 确定
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.form.maxPercentage == '' && this.form.minPercentage == '') {
            this.$message.error('请完善范围指数！')
            return
          }
          if (this.titleName == 'add') {
            createGoods(this.form).then((res) => {
                if(res.code == 200){
                    this.loading = false
                    this.visible = false
                    this.setClear()
            this.$emit('renew')

                }
            }).finally(() => {
                this.loading = false
            })
          } else {
            updateGoods(this.form).then((res) => {
                if(res.code == 200){
                    this.loading = false
                    this.visible = false
                    this.setClear()
                    this.$emit('renew')
                }
            }).finally(() => {
                this.loading = false
            })
          }
        }
      })
    },

    // 获取详情
    setData(row) {
      if (row.id) {
        getDeviceEdit(row.id).then((res) => {
          if (res) {
            this.setform(res)
          }
        })
      }
    },
    setform(data) {
      nextTick(() => {
        for (let k in this.form) {
          if (data[k] || data[k] === 0) {
            this.form[k] = data[k]
          }
        }
      })
    },

    // 取消
    handleCancel() {
      console.log('取消')
      this.visible = false
      this.setClear()
    },
    // 清除
    setClear() {
      this.form.gender = undefined
      this.form.id = ''
      this.form.indexName = ''
      this.form.maxPercentage = ''
      this.form.minPercentage = ''
    },
  },
}
</script>
<style>
.listNum {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.content {
  width: 100%;
  margin: 0px 0px 60px 0px;
}

.submitClass {
  margin-left: 20px;
}
</style>