import { render, staticRenderFns } from "./detailFinanceForm.vue?vue&type=template&id=123c962e&scoped=true"
import script from "./detailFinanceForm.vue?vue&type=script&lang=js"
export * from "./detailFinanceForm.vue?vue&type=script&lang=js"
import style0 from "./detailFinanceForm.vue?vue&type=style&index=0&id=123c962e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "123c962e",
  null
  
)

export default component.exports