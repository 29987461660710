import request from '@/utils/request'


// 查询商品列表
export function listGoods(query) {
  return request({
    url: '/finance/goods/list',
    method: 'get',
    params: query
  })
}

// 查询商品分页
export function pageGoods(query) {
  return request({
    url: '/finance/goods/page',
    method: 'get',
    params: query
  })
}
export function getSell(query) {
  return request({
    url: '/finance/goods/detail',
    method: 'get',
    params: query
  })
}
// 修改店铺
export function updateSell(data) {
  return request({
    url: '/finance/goods/edit',
    method: 'post',
    data: data
  })
}