import request from '@/utils/request'


// 查询默认常用语列表
export function listCommondef(query) {
  return request({
    url: '/user/commondef/list',
    method: 'get',
    params: query
  })
}

// 查询默认常用语分页
export function pageCommondef(query) {
  return request({
    url: '/user/commondef/page',
    method: 'get',
    params: query
  })
}

// 查询默认常用语详细
export function getCommondef(data) {
  return request({
    url: '/user/commondef/detail',
    method: 'get',
    params: data
  })
}

// 新增默认常用语
export function addCommondef(data) {
  return request({
    url: '/user/commondef/add',
    method: 'post',
    data: data
  })
}

// 修改默认常用语
export function updateCommondef(data) {
  return request({
    url: '/user/commondef/edit',
    method: 'post',
    data: data
  })
}

// 删除默认常用语
export function delCommondef(data) {
  return request({
    url: '/user/commondef/delete',
    method: 'post',
    data: data
  })
}
