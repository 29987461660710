import request from '@/utils/request'


// 查询快捷回复列表
export function listQuickReply(query) {
  return request({
    url: '/common/quick/reply/list',
    method: 'get',
    params: query
  })
}

// 查询快捷回复分页
export function pageQuickReply(query) {
  return request({
    url: '/common/quick/reply/page',
    method: 'get',
    params: query
  })
}

// 查询快捷回复详细
export function getQuickReply(data) {
  return request({
    url: '/common/quick/reply/detail',
    method: 'get',
    params: data
  })
}

// 新增快捷回复
export function addQuickReply(data) {
  return request({
    url: '/common/quick/reply/add',
    method: 'post',
    data: data
  })
}

// 修改快捷回复
export function updateQuickReply(data) {
  return request({
    url: '/common/quick/reply/edit',
    method: 'post',
    data: data
  })
}

// 删除快捷回复
export function delQuickReply(data) {
  return request({
    url: '/common/quick/reply/delete',
    method: 'post',
    data: data
  })
}
