<template>
  <global-footer class="footer custom-render">
    <template v-slot:links>
<!--      <a href="#" target="_blank">帮助</a>-->
<!--      <a href="http://121.89.202.159/share/privacyPolicy/privacyPolicy.html" target="_blank">隐私</a>
      <a href="http://121.89.202.159/share/registerAgreement/registerAgreement.html" target="_blank">条款</a>-->

      <a href="https://www.shiwei.love/" target="_blank">广东省侍卫健康科技产业有限公司 版权所有
        © 2021-2024 shiwei.love  All Rights Reserved</a>

    </template>
  </global-footer>
</template>

<script>
import { GlobalFooter } from '@/components/ProLayout'

export default {
  name: 'ProGlobalFooter',
  components: {
    GlobalFooter
  }
}
</script>
