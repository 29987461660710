<template>
  <a-drawer width="40%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" :rules="rules">
      <a-form-model-item :label="$t('订单取消原因.类型')" prop="type">
        <a-select :placeholder="$t('通用.输入.请选择')+$t('订单取消原因.类型')" v-model="form.type" allowClear>
          <a-select-option v-for="(item, index) in customDict.OrderCancelTypeEnum" :value="item.type"
                           :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :label="$t('订单取消原因.类型关联')" prop="labelList">
        <a-select mode="multiple" :placeholder="$t('通用.输入.请选择')+$t('订单取消原因.类型关联')" style="width: 100%"
                  v-model="form.labelList" allowClear>
          <a-select-option v-for="(item, index) in customDict.OrderCancelTypeEnum" :value="item.type+''" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :label="$t('订单取消原因.名称')" prop="name">
        <a-input v-model="form.name" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('订单取消原因.名称')"/>
      </a-form-model-item>
      <!--      <a-form-item :label="this.$t('通用.文本.多语言配置')" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" class="margin-bottom-10">
              <a-table :data-source="form.localeList" :columns="localeColumns" rowKey="id" :pagination="false" bordered>
                <span slot="locale" slot-scope="text, record">
                 <custom-dict-tag :options="customDict.LocaleEnum" :value="record.locale"/>
                </span>
                <div slot="name" slot-scope="text, record" >
                  <a-input v-model="record.name" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('订单取消原因.名称')" @blur.native.capture="validatorLocaleName"   />
                </div>
                <div slot="action" slot-scope="text, record">
                  <a-button type="primary" @click="getTranslation(record)">
                    {{$t('通用.按钮.翻译')}}
                  </a-button>
                </div>
              </a-table>
              <span style="color: red;">{{ errorMessage }}</span>
            </a-form-item>-->


      <a-form-model-item :label="$t('通用.文本.排序')" prop="sort">
        <a-input-number :maxLength="6" style="width: 50%" @change="limitSort" v-model="form.sort"
                        :placeholder="$t('通用.输入.请输入')+$t('通用.文本.排序')"/>
      </a-form-model-item>
      <a-form-model-item :label="$t('订单取消原因.是否显示')" prop="isDisplay">
        <a-radio-group v-model="form.isDisplay" button-style="solid">
          <a-radio-button v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl"/>
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{ $t('通用.按钮.保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{ $t('通用.按钮.取消') }}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getCancelCause, addCancelCause, updateCancelCause} from '@/api/order/cancelCause'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";
import {translation} from "@/api/tool/common";


export default {
  name: 'CreateForm',
  props: {},
  components: {
    CustomDictTag,
  },
  data() {
    return {
      errorMessage: undefined,
      labelList: [],
      localeColumns: [
        /*{
          title: this.$t('通用.文本.语言'),
          dataIndex: 'locale',
          key: 'locale',
          scopedSlots: {customRender: 'locale'}
        }, */
        {
          title: this.$t('订单取消原因.名称'),
          dataIndex: 'name',
          scopedSlots: {customRender: 'name'},
          key: 'name'
        }, {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'action',
          key: 'action',
          slots: {title: 'addbtn', customRender: 'name'},
          scopedSlots: {customRender: 'action'}
        }
      ],
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,
        labelList: [],
        type: null,
        localeList: [],
        name: null,
        sort: null,
        isDisplay: true,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          {required: true, message: this.$t('订单取消原因.类型') + this.$t('通用.文本.不能为空'), trigger: 'change'}
        ],
        labelList: [
          {required: true, message: this.$t('订单取消原因.类型') + this.$t('通用.文本.不能为空'), trigger: 'change'}
        ],
        name: [
          {required: true, message: this.$t('订单取消原因.名称') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        sort: [
          {required: true, message: this.$t('通用.文本.排序') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        isDisplay: [
          {required: true, message: this.$t('订单取消原因.是否显示') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ]
      }
    }
  },
  filters: {},
  created() {

  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        labelList: [],
        type: null,
        name: null,
        sort: null,
        localeList: [],
        isDisplay: true,
      }

    },
    limitSort(sort) {
      console.log(sort)
      let val = sort;
      if (val == null || val == undefined || val == '' || val < 0) {
        val = 0
      }
      if (val > 999999) {
        val = 999999
      }
      this.form.sort = val
    },
    /*text 要翻译的字段*/
    getTranslation(record) {
      if (!this.form.name) {
        return
      }
      let data = {"text": this.form.name, "locale": record.locale};
      translation(data).then(response => {
        record.name = response.data
      })
    },
    validatorLocaleName() {
      let errorMessage = "";
      var localeList = this.form.localeList;
      let localeEnum = this.customDict.LocaleEnum;
      localeList.forEach(e => {
        if (!e.name) {
          let localeName = localeEnum.find(locale => locale.type === e.locale).name;
          errorMessage += localeName + this.$t('通用.文本.不能为空') + ';';
        }
      })
      if (errorMessage) {
        this.errorMessage = errorMessage;
        return false;
      }
      this.errorMessage = "";
      return true;
    },
    initLocale() {
      //初始化多语言
      let localeList = [];
      let i = 1;
      this.customDict.LocaleEnum.forEach(e => {
        localeList.push({"id": i, "locale": e.type, 'name': null})
        i++;
      })
      this.form.localeList = localeList;
    },


    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      // this.initLocale();
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCancelCause({"id": id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        /* let localeValid = this.validatorLocaleName();
         if (!localeValid) {
           return false;
         }*/
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateCancelCause(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addCancelCause(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
