<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 2:操作 -->
      <div class="table-operations">
        <!-- v-hasPermi="['goods:goods:add']" -->
        <a-button type="primary" @click="handleAdd()" class="plus">
          <a-icon type="plus" />
          {{ $t('通用.按钮.新增') }}
        </a-button>
      </div>
      <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="false">
        <span slot="gender" slot-scope="text">{{ getGenderText(text) }}</span>
        <span slot="operation" slot-scope="text, record">
          <a @click="handleUpdate(record, undefined)" v-hasPermi="['goods:goods:edit']">
            <a-icon type="edit" />{{ $t('通用.按钮.修改') }}
          </a>

          <a-divider type="vertical" v-hasPermi="['goods:goods:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['goods:goods:remove']">
            <a-icon type="delete" />{{ $t('通用.按钮.删除') }}
          </a>
        </span>
      </a-table>
      <saveView ref="saveRef" @renew="setRenew"></saveView>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { getGoodsIndex,delGoods } from '@/api/goods/goodsIndex'
import saveView from './save.vue'
export default {
  components: {
    saveView,
  },
  data() {
    return {
      loading: false,
      data:[],
      columns: [
        {
          title: '适用性别',
          dataIndex: 'gender',
          key: 'gender',
          width: 120,
          scopedSlots: { customRender: 'gender' },
          align: 'center',
        },
        {
          title: '指数名称',
          dataIndex: 'indexName',
          key: 'indexName',
          width: 500,
          scopedSlots: { customRender: 'indexName' },
          align: 'center',
        },
        {
          title: '销魂指数随机设定范围',
          dataIndex: null,
          key: 'percentageRange',
          ellipsis: true,
          width: 180,
          customRender: (text, record) => {
            return `${record.minPercentage}% - ${record.maxPercentage}%`
          },
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          fixed: 'right',
          width: 210,
          scopedSlots: { customRender: 'operation' },
        },
      ],
    }
  },

  created() {
    this.getIndex()
  },

  methods: {    // 获取列表
    getIndex() {
      this.loading = true
      getGoodsIndex().then((res) => {
        console.log(res.data)
        this.data = res.data
        this.loading = false
      })
    },
    // 添加
    handleAdd() {
      this.$refs.saveRef.open('add')
    },
    // 删除
    handleDelete(row) {
      var that = this
      if (row.id) {
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk() {
          let data = []
          that.ids.map(id => data.push({"id": id}))
          return delGoods(data)
            .then(() => {
              that.getList()
              that.$message.success(
                that.$t('通用.文本.删除成功'),
                3
              )
            })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    // 修改
    handleUpdate(item) {
      this.$refs.saveRef.open('edit')
      this.$refs.saveRef.setData(item)
    },

    // 更新table
    setRenew() {
      this.getIndex()
    },

    // 性别判断
    getGenderText(gender) {
      switch (gender) {
        case 0:
          return '男性'
        case 1:
          return '女性'
        case 2:
          return '通用'
        default:
          return ''
      }
    }
  },
}
</script>

<style scoped>
/* 这里可以添加你的自定义样式 */
.listNum {
  display: flex;
  align-items: center;
  justify-content: flex_start;
}
table-row.table-cell:nth-child(3) {
  text-align: left;
}
</style>