import request from '@/utils/request'


// 查询商品库存记录列表
export function listSkuRecordLog(query) {
  return request({
    url: '/goods/sku/record/log/list',
    method: 'get',
    params: query
  })
}

// 查询商品库存记录分页
export function pageSkuRecordLog(query) {
  return request({
    url: '/goods/sku/record/log/page',
    method: 'get',
    params: query
  })
}

// 查询商品库存记录详细
export function getSkuRecordLog(data) {
  return request({
    url: '/goods/sku/record/log/detail',
    method: 'get',
    params: data
  })
}

// 新增商品库存记录
export function addSkuRecordLog(data) {
  return request({
    url: '/goods/sku/record/log/add',
    method: 'post',
    data: data
  })
}

// 修改商品库存记录
export function updateSkuRecordLog(data) {
  return request({
    url: '/goods/sku/record/log/edit',
    method: 'post',
    data: data
  })
}

// 删除商品库存记录
export function delSkuRecordLog(data) {
  return request({
    url: '/goods/sku/record/log/delete',
    method: 'post',
    data: data
  })
}
