<template>
  <a-drawer width="70%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ $t('销售订单.订单详情') }}</b>
    </a-divider>
    <div class="order-info">
      <table>
        <tr>
          <td style="vertical-align: top;background-color:#fbfbfb;">
            <a-card :bordered="false" :title="$t('销售订单.订单信息')"
                    :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                    :bodyStyle="{'background-color':'#fbfbfb'}">
              <table class="info-table">
                <tr>
                  <td class="first">{{ $t('商品对账单.账单编号') }}：</td>
                  <td class="data">
                    {{ this.record.id }}
                  </td>
                  <td class="first">{{ $t('商品对账单.账单日期') }}：</td>
                  <td class="data">
                    {{ this.record.billDate }}
                  </td>
                  <td class="first">{{ $t('商品对账单.账单月份') }}：</td>
                  <td class="data">
                    {{ this.record.billMonth }}
                  </td>
                  <td class="first">{{ $t('销售订单.订单编号') }}：</td>
                  <td class="data">
                    {{ this.record.orderNo }}
                  </td>

                </tr>

                <tr>
                  <td class="first">{{ $t('售后订单.售后状态') }}：</td>
                  <td class="data">
                    {{ this.record.refundStatusName }}
                  </td>

                  <td class="first">{{ $t('售后订单.售后类型') }}：</td>
                  <td class="data">
                    {{ this.record.refundTypeName }}
                  </td>
                  <td class="first">{{ $t('销售订单.订单来源') }}：</td>
                  <td class="data">
                    {{ this.record.sourceName }}
                  </td>
                  <td class="first">{{ $t('销售订单.下单时间') }}：</td>
                  <td colspan="7">
                    {{ this.record.orderTime }}
                  </td>
                </tr>
                <tr>
                  <td class="first">{{ $t('销售订单.支付方式') }}：</td>
                  <td class="data">
                    {{ this.record.payTypeName }}
                  </td>
                  <td class="first">{{ $t('销售订单.三方订单号') }}：</td>
                  <td class="data">
                    {{ this.record.outTradeNo }}
                  </td>
                  <td class="first">{{ $t('销售订单.支付时间') }}：</td>
                  <td class="data">
                    {{ this.record.payTime }}
                  </td>
                  <td class="first">{{ $t('商品.商品编号') }}：</td>
                  <td class="data">
                    {{ this.record.goodsCode }}
                  </td>
                </tr>

                <tr>
                  <td class="first">{{ $t('销售订单.运费金额') }}：</td>
                  <td class="data">
                    ¥{{ this.record.shippingPayAmount }}
                  </td>
                  <td class="first">{{ $t('销售订单.商品实付金额') }}：</td>
                  <td class="data">
                    ¥{{ this.record.payAmount }}
                  </td>
                  <td class="first">{{ $t('销售订单.订单状态') }}：</td>
                  <td class="data">
                    {{ this.record.orderStatusName }}
                  </td>
                </tr>
              </table>
            </a-card>
          </td>
        </tr>
        <tr>
          <td style="vertical-align: top;background-color:#fbfbfb;">
            <a-card :bordered="false" :title="$t('销售订单.商品信息')"
                    :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                    :bodyStyle="{'background-color':'#fbfbfb'}">
              <table class="info-table">
                <tr>
                  <td class="first">{{ $t('商品.商品名称') }}：</td>
                  <td class="data">
                    {{ this.record.goodsName }}
                  </td>

                  <td class="first">{{ $t('销售订单.商品数量') }}：</td>
                  <td class="data">
                    {{ this.record.goodsCount }}
                  </td>
                  <td class="first">{{ $t('商品.商品价格') }}：</td>
                  <td class="data">
                    ¥{{ this.record.originalPrice }}
                  </td>
                  <td class="first">{{ $t('商品.SKU名称') }}：</td>
                  <td class="data">
                    ¥{{ this.record.skuName }}
                  </td>
                </tr>
              </table>
            </a-card>
          </td>
        </tr>
        <tr>
          <td style="vertical-align: top;background-color:#fbfbfb;">
            <a-card :bordered="false" :title="$t('销售订单.对账信息')"
                    :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                    :bodyStyle="{'background-color':'#fbfbfb'}">
              <table class="info-table">
                <tr>
                  <td class="data">
                    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form">
                      <a-form-model-item :label="$t('销售订单.对账图片')" prop="picture">
                        <OssUploadSingle v-model="form.picture" type="img" :maxSize="200"></OssUploadSingle>
                        <span>推荐尺寸800*800px</span>
                      </a-form-model-item>
                    </a-form-model>
                  </td>
                  <td class="data">
                    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form">
                      <a-form-model-item :label="$t('销售订单.对账备注')" prop="remark">
                        <a-input v-model="form.remark" type="textarea" :placeholder="$t('通用.输入.请输入')+$t('销售订单.对账备注')"/>
                      </a-form-model-item>
                    </a-form-model>
                  </td>
                  <td class="data">
                    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form">
                      <a-form-model-item class="margin-bottom-10" :label="$t('销售订单.是否对账')" prop="complete">
                        <a-radio-group v-model="form.complete" button-style="solid">
                          <a-radio-button v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
                            {{ item.name }}
                          </a-radio-button>
                        </a-radio-group>
                      </a-form-model-item>
                    </a-form-model>
                  </td>
                </tr>
              </table>
            </a-card>
          </td>
        </tr>
      </table>
    </div>
    <div class="bottom-control">
      <a-space>
        <a-button type="primary" :loading="submitLoading" @click="submitForm">
          {{ $t('销售订单.对账') }}
        </a-button>
        <a-button type="dashed" @click="cancel">
          {{ $t('通用.按钮.关闭') }}
        </a-button>
      </a-space>
    </div>
  </a-drawer>
</template>

<script>
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {getSell,updateSell} from '@/api/finance/goods'
import OssUploadSingle from "@/components/OssUploadSingle";

export default {
  name: 'detailForm',
  props: {},
  components: {
    CustomDictTag, OssUploadSingle,
  },
  data() {
    return {
      record: {
        id: null,
        orderAddressDetail: {},
        orderActivityDetail: {},
      },
      labelCol: {span: 6},
      wrapperCol: {span: 14},
      submitLoading: false,
      // 表单参数
      form: {
        id: null,
        outTradeNo: null,
        remark: null,
        picture: null,
        complete:null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
    }
  },
  filters: {},
  created() {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        outTradeNo: null,
        remark: null,
        picture: null,
        complete:null,
      }
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },

    /** 修改按钮操作 */
    handleDetail(id) {debugger
      debugger
      this.reset()
      this.formType = 2
      this.form.id = id
      getSell({"id": id}).then(response => {
        this.record = response.data
        this.form.picture=response.data.picture
        this.form.remark=response.data.remark
        this.form.complete=response.data.complete
        this.open = true
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {debugger
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateSell(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, .85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.message-text {
  font-family: MicrosoftYaHei;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 3rem;
  letter-spacing: 0rem;
  color: #333333;
  width: 50%;
}

.fontblack {
  color: #999999
}

.img2 {
  width: .81rem;
  height: .8rem;
  float: right;
}


.orderTitle {
  font-size: 1rem;
  color: #333333;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  height: 2.5rem;
}

.orderDetail {
  font-size: 0.8rem;
  color: #666666;
  text-align: left;
}

.border-ceter {
  width: 92%;
  padding-left: 15px;
  padding-right: 15px;
}

.pay-button {
  width: 88%;
  height: 2.6rem;
  position: relative;
  background-color: red;
  color: white;

  margin-left: 6%;
}

ul li {
  list-style: none;
  font-size: 1rem;
}

ul {
  padding-left: 1.5rem
}

.track-rcol {
}

.track-list {
  position: relative;
}

.track-list li {
  position: relative;
  padding: 0px 0 1.5rem 25px;
  line-height: 1rem;
  border-left: 1px solid #d9d9d9;
  color: #999;
}

.track-list li.first {
  color: red;
  padding-top: 0;
  width: 100%;
  text-align: left;
  border-left: 1px solid #d9d9d9;
}

.track-list li .node-icon {
  position: absolute;
  left: -5.5px;
  border-radius: 0.25rem;
  width: 0.5rem;
  height: 0.5rem;
  top: 4px;
  background-color: #999999;
}

.track-list li.first .node-icon {
  background-position: 0-72px;
  background-color: red;
  width: 1rem;
  z-index: 2;
  height: 1rem;
  position: absolute;
  left: -9px;
  top: 0;
  border-radius: 0.5rem;
}

.track-list li .time {
  margin-right: 20px;
  position: relative;
  top: 4px;
  display: inline-block;
  vertical-align: middle;
  background-color: white;
  color: #999;
  width: 100%;
  text-align: left;
}

.track-list li .txt {
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: #999;
}

.track-list li.first .time {
  text-align: left;
  width: 94%;
  color: red;
}

.track-list li.first .txt {
  color: red;
  text-align: left;
  width: 94%;
}

.track-list li.finall {
  position: relative;
  padding: 0px 0 1.5rem 25px;
  line-height: 18px;
  border-color: white;
  border-left: 1px solid #ffffff;
  color: #999;
}

.track-list li.finall .div-spilander {
  width: 1px;
  position: absolute;
  position: absolute;
  left: -1.5px;
  height: 0.5rem;
  background-color: #d9d9d9;
}

.order-info {
  border: 1px solid #e8e8e8;
  margin-top: 40px;

  .info-table {
    tr {
      height: 50px;
    }

    td {
      display: table-cell;
      vertical-align: top;
      font-size: 16px;
    }

    .first {
      width: 100px;
    }

    .data {
      width: 300px;
    }
  }
}

.detailPanel {
  dd, dt {
    display: inline-block;
    vertical-align: center;
  }

  dt img {
    margin: 0 20px 5px 0px;
  }

  dd {
    h3 {
      font-weight: bold;
    }
  }

  .trade-detail-prompt {
    margin-left: 35px;

    li {
      font-size: 13px !important;

      .dotted-node {
        padding: 0 5px;
      }
    }
  }
}

.order-money {
  .om-c1 {
    font-size: 14px;
    color: #666
  }

  .om-c2 {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }

  .om-c3 {
    font-size: 16px;
    color: #DE3636;
    font-weight: bold;
  }

  td {
    text-align: right;
  }

  float: right;
  margin: 20px 40px 20px 0;
}
</style>
