import request from '@/utils/request'


// 查询客服聊天记录融云列表
export function listNoticeRecord(query) {
  return request({
    url: '/customer/notice/record/list',
    method: 'get',
    params: query
  })
}

// 查询客服聊天记录融云分页
export function pageNoticeRecord(query) {
  return request({
    url: '/customer/notice/record/page',
    method: 'get',
    params: query
  })
}

// 查询客服聊天记录融云详细
export function getNoticeRecord(data) {
  return request({
    url: '/customer/notice/record/detail',
    method: 'get',
    params: data
  })
}

// 新增客服聊天记录融云
export function addNoticeRecord(data) {
  return request({
    url: '/customer/notice/record/add',
    method: 'post',
    data: data
  })
}

// 修改客服聊天记录融云
export function updateNoticeRecord(data) {
  return request({
    url: '/customer/notice/record/edit',
    method: 'post',
    data: data
  })
}

// 删除客服聊天记录融云
export function delNoticeRecord(data) {
  return request({
    url: '/customer/notice/record/delete',
    method: 'post',
    data: data
  })
}
