import request from '@/utils/request'
import { method } from 'lodash'


// 随机生成商品指数
export function random(query) {
  return request({
    url: '/api/randomIndex' ,
    method: 'get',
    params: query
  })
}

// 查询所有商品指数
export function getGoodsIndex() {
  return request({
    url: '/goods/set/list',
    method: 'get'
  })
}



// 查询商品详细
export function getDeviceEdit(data) {
  return request({
    url: '/goods/set/detail',
    method: 'get',
    params: data
  })
}

// 新增商品
export function createGoods(data) {
  return request({
    url: '/goods/set/add',
    method: 'post',
    data: data
  })
}

// 修改商品
export function updateGoods(data) {
  return request({
    url: '/goods/set/edit',
    method: 'post',
    data: data
  })
}

// 删除商品
export function delGoods(data) {
  return request({
    url: '/goods/set/delete',
    method: 'post',
    data: data
  })
}
