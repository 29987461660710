<template>
  <a-drawer width="50%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" :rules="rules">
      <a-form-model-item prop="remark">
        <span slot="label">
          <a-tooltip>
            {{ $t('标题') }}<template slot="title">{{ $t('标题') }}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="videoContent">
        <span slot="label">
          <a-tooltip>
            {{ $t('动态描述') }}<template slot="title">{{ $t('动态描述') }}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.videoContent" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear/>
      </a-form-model-item>
      <!--      <a-form-model-item prop="videoType">
              <span slot="label">
                <a-tooltip>
                  {{ $t('发布类型') }}
                  <template slot="title">{{ $t('发布类型') }}</template>
                </a-tooltip>
              </span>
              <a-radio-group v-model="form.videoType" button-style="solid">
                <a-radio-button v-for="(item, index) in customDict.VideoTypeEnum" :value="item.type" :key="index">
                  {{ item.name }}
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>-->


      <a-form-model-item prop="videoUrlList">
        <span slot="label">
          <a-tooltip>
            {{ $t('动态图片') }}<template slot="title">{{ $t('动态图片') }}</template>
          </a-tooltip>
        </span>
        <OssUploadMulti v-model="form.videoUrlList" :isArrayData="false" :prefix="video" type="img"></OssUploadMulti>
      </a-form-model-item>

      <a-form-model-item prop="videoPic">
        <span slot="label">
          <a-tooltip>
            {{ $t('动态视频') }}<template slot="title">{{ $t('动态视频') }}</template>
          </a-tooltip>
        </span>
        <OssUploadSingle v-model="form.videoPic" type="video" :prefix="video" :maxSize="200"></OssUploadSingle>
      </a-form-model-item>

      <!--      <a-form-model-item prop="videoPid" v-if="form.videoType===1">
              <span slot="label">
                <a-tooltip>
                  {{ $t('空间分类') }}
                  <template slot="title">{{ $t('空间分类') }}</template>
                </a-tooltip>
              </span>
              <a-select style="width: 100%" v-model="form.videoPid" placeholder="请选择空间分类">
                <a-select-option v-for="(item, index) in brandList" :value="item.id" :key="index">
                  {{ item.classifyName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>-->


      <a-form-model-item prop="videoBusinessId">
        <span slot="label">
          <a-tooltip>
            {{ $t('选择商品') }}<template slot="title">{{ $t('选择商品') }}</template>
          </a-tooltip>
        </span>
        <a-button type="dashed" @click="openProdSelect" danger>
          <span style="color: dodgerblue"> {{ $t('通用.文本.选择商品') }}</span>
        </a-button>
      </a-form-model-item>
      <!-- 数据展示 -->
      <a-table
        :loading="goodsLoading"
        :scroll="{ x: '160%' }"
        rowKey="id"
        :size="tableSize"
        :columns="goodsColumns"
        :data-source="form.goodsList"
        :pagination="false"
        :bordered="tableBordered">
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <template slot="picture" slot-scope="text, record">
          <div>
            <img v-if="record.picture" :src="record.picture" style="width:60px;height:62px;"
                 @click="handleRecordPreview(record.picture)" alt="none"/>
          </div>
        </template>

        <span slot="categoryId" slot-scope="text, record">
            {{ getCategoryNameById(record.categoryId) }}
        </span>
        <span slot="brandId" slot-scope="text, record">
             {{ getBrandNameById(record.brandId) }}
          </span>
        <span slot="status" slot-scope="text, record">
           <custom-dict-tag :options="customDict.GoodsStatusEnum" :value="record.status"/>
          </span>
      </a-table>


      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{ $t('通用.按钮.保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{ $t('通用.按钮.取消') }}
          </a-button>
        </a-space>
      </div>
      <radio-goods-select-form ref="RadioGoodsSelectForm"
                               @goodsSelect="indexGoodsModalSelect"></radio-goods-select-form>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getVideo, addVideo, updateVideo} from '@/api/video/video'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";
import Video from "@/views/video/index.vue";
import OssUploadSingle from "@/components/OssUploadSingle";
import OssUploadMulti from "@/components/OssUploadMulti";
import {getRecommendCategory, addRecommendCategory, listRecommendCategory} from '@/api/video/recommendCategory'
import RadioGoodsSelectForm from "@/views/zb/seckill/modules/RadioSelectForm";

export default {
  name: 'CreateForm',
  props: {},
  components: {
    Video,
    CustomDictTag, OssUploadSingle, OssUploadMulti, RadioGoodsSelectForm

  },
  data() {
    return {
      //-------------------------------------------视频开始
      playerOptions: {
        // videojs options
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false,// 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        language: 'zh-CN',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4",
          src: ""
        }],
        poster: "",
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试' //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      },

      //-------------------------------------------视频结束
      brandList: [],
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: false,
      previewVideoVisible: false,
      loading: '',
      goodsColumns: [
        /*{
          title: this.$t('商品.商品编号'),
          dataIndex: 'goodsCode',
          width: 100,
          fixed: 'left',
        },*/
        {
          title: this.$t('商品.商品名称'),
          dataIndex: 'goodsName',
          width: 180,
          fixed: 'left',
        },
        {
          title: this.$t('商品.商品主图'),
          dataIndex: 'picture',
          width: 60,
          scopedSlots: {customRender: 'picture'},
        },
        /*{
          title: this.$t('商品.商品分类'),
          dataIndex: 'categoryId',
          width: 60,
          scopedSlots: {customRender: 'categoryId'},
        },
        {
          title: this.$t('商品.品牌'),
          dataIndex: 'brandId',
          width: 50,
          scopedSlots: {customRender: 'brandId'},
        },*/
        {
          title: this.$t('商品.市场价'),
          width: 50,
          dataIndex: 'marketPrice',
        },
        {
          title: this.$t('商品.商品价'),
          width: 50,
          dataIndex: 'price',
        },],
      // 表单参数
      form: {
        id: null,
        goodsList: [],
        videoContent: null,

        videoPic: null,
        videoUrlList: null,
        videoUrl: null,

        videoBusinessId: null,

        playNum: null,

        likeNum: null,

        videoState: null,

        createTime: null,

        videoPid: null,

        videoPids: null,

        videoDuration: null,

        putInHours: null,

        auditOpinion: null,

        atUser: null,

        videoType: 0,

        topicId: null,

        userId: null,

        shareNum: null,

        replyNum: null,

        isReward: null,

        videoBusinessType: null,

        pubLat: null,

        pubLng: null,

        limitShow: null,

        address: null,

        lastEditTime: null,

        remark: null,

        pubCity: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        videoUrlList: [
          {required: true, message: '动态图片不能为空', trigger: 'blur'}
        ],
        remark: [
          {required: true, message: '标题不能为空', trigger: 'blur'}
        ],
        videoContent: [
          {required: true, message: '动态描述不能为空', trigger: 'blur'}
        ]
      }
    }
  },
  filters: {},
  created() {
    this.getBrandList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },


  methods: {
    getBrandList() {
      listRecommendCategory().then(response => {
        this.brandList = response.data
      })
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    onClose() {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        goodsList: [],

        id: null,
        videoContent: null,
        videoPic: null,
        videoUrlList: null,
        videoUrl: null,
        videoBusinessId: null,
        playNum: null,
        likeNum: null,
        videoState: null,
        createTime: null,
        videoPid: null,
        videoPids: null,
        videoDuration: null,
        putInHours: null,
        auditOpinion: null,
        atUser: null,
        videoType: 0,
        topicId: null,
        userId: null,
        shareNum: null,
        replyNum: null,
        isReward: null,
        videoBusinessType: null,
        pubLat: null,
        pubLng: null,
        limitShow: null,
        address: null,
        lastEditTime: null,
        remark: null,
        pubCity: null
      }

    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    indexGoodsModalSelect(records) {
      this.form.videoBusinessId = records[0].id
      this.form.goodsList = records
      console.log('商品选择了', this.form.goodsList)
    },
    openProdSelect() {
      console.log('打开商品选择框,单选模式')
      this.$refs.RadioGoodsSelectForm.openGoods(undefined, [this.form.goodsId]);
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVideo({"id": id}).then(response => {
        this.form = response.data
        // 视频路径
        /*if (this.form.videoType=="0"){
          this.playerOptions.sources[0].src = this.form.videoUrl;
        }*/
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateVideo(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addVideo(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
/** Button按钮间距 */
.ant-btn {
  margin-left: 30px;
  margin-bottom: 30px;
  float: right;
}

.imageDiv {
  float: left;
  width: 150px;
  height: 200px;
  margin-right: 10px;
  margin: 0 8px 8px 0;
}

.imageDiv .imageDivBorder {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.imageDiv .imageDivBorder img {
  width: 100%;
  height: 100%;
}
</style>