<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="senderUserId" label="发送人账号" >
        <a-input v-model="form.senderUserId" disabled=""/>
      </a-form-model-item>
      <a-form-model-item prop="targetId" label="接收人账号" >
        <a-input v-model="form.targetId" disabled/>
      </a-form-model-item>
      <a-form-model-item prop="senderNickName" label="发送人昵称" >
        <a-input v-model="form.senderNickName" disabled=""/>
      </a-form-model-item>
      <a-form-model-item prop="targetNickName" label="接收人昵称" >
        <a-input v-model="form.targetNickName" disabled/>
      </a-form-model-item>
      <a-form-model-item prop="content2" label="聊天内容" v-if="form.messageType==='RC:TxtMsg'">
        <a-input v-model="form.content2" disabled="" />
      </a-form-model-item>
      <a-form-model-item prop="imageUri" label="聊天图片" v-if="form.messageType==='RC:ImgMsg'" >
        <OssUploadSingle v-model="form.imageUri" type="img" :prefix="goods" :maxSize="200"></OssUploadSingle>
<!--        <a-input v-model="form.imageUri" disabled="" />-->
      </a-form-model-item>
      <a-form-model-item prop="sightUrl" label="聊天视频"  v-if="form.messageType==='RC:SightMsg'" >
<!--        <a-input v-model="form.sightUrl" disabled="" />-->
        <OssUploadSingle v-model="form.sightUrl" type="video" :prefix="goods" :maxSize="200"></OssUploadSingle>
      </a-form-model-item>

      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
<!--      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>-->
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getNoticeRecord, addNoticeRecord, updateNoticeRecord } from '@/api/customer/noticeRecord'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";

import Editor from '@/components/Editor'
import OssUploadSingle from "@/components/OssUploadSingle";
import OssUploadMulti from "@/components/OssUploadMulti";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,OssUploadSingle,
    Editor
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        canincludeexpansion: null,

        channelid: null,

        channeltype: null,

        content: null,
        content2:null,
        imageUri: null,
        sightUrl: null,
        conversationtype: null,

        directeduserids: null,

        disablenotification: null,

        ismentioned: null,

        ismodifymessage: null,

        isofflinemessage: null,

        ispersited: null,

        isstatusmessage: null,

        messagedirection: null,

        messageid: null,

        messagetype: null,

        messageuid: null,

        pushconfig: null,

        readreceiptinfo: null,

        receivedstatus: '0',

        receivedtime: null,

        senderuserid: null,

        sentstatus: '0',

        senttime: null,

        targetid: null,

        direction: null,

        text: null,

        offline: null,

        remote: null,

        thumbnailbase64string: null,

        readType: null,

        privateType: null,

        businessId: null,

        remark: null,

        createTime: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        canincludeexpansion: [
          { required: true, message: this.$t('客服聊天记录融云.${comment}')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        disablenotification: [
          { required: true, message: this.$t('客服聊天记录融云.${comment}')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        ismentioned: [
          { required: true, message: this.$t('客服聊天记录融云.${comment}')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        isofflinemessage: [
          { required: true, message: this.$t('客服聊天记录融云.${comment}')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        ispersited: [
          { required: true, message: this.$t('客服聊天记录融云.${comment}')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        isstatusmessage: [
          { required: true, message: this.$t('客服聊天记录融云.${comment}')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        offline: [
          { required: true, message: this.$t('客服聊天记录融云.${comment}')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        readType: [
          { required: true, message: this.$t('客服聊天记录融云.是否已读')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],
        privateType: [
          { required: true, message: this.$t('客服聊天记录融云.是否私密')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        canincludeexpansion: null,
        channelid: null,
        channeltype: null,
        content: null,
        content2:null,
        imageUri: null,
        sightUrl: null,
        conversationtype: null,
        directeduserids: null,
        disablenotification: null,
        ismentioned: null,
        ismodifymessage: null,
        isofflinemessage: null,
        ispersited: null,
        isstatusmessage: null,
        messagedirection: null,
        messageid: null,
        messagetype: null,
        messageuid: null,
        pushconfig: null,
        readreceiptinfo: null,
        receivedstatus: '0',
        receivedtime: null,
        senderuserid: null,
        sentstatus: '0',
        senttime: null,
        targetid: null,
        direction: null,
        text: null,
        offline: null,
        remote: null,
        thumbnailbase64string: null,
        readType: null,
        privateType: null,
        businessId: null,
        remark: null,
        createTime: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getNoticeRecord({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = "详情"
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateNoticeRecord(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addNoticeRecord(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
