var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":_vm.mode[_vm.titleName],"visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('div',{staticClass:"content"},[_c('a-form-model',{ref:"form",attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"model":_vm.form,"rules":_vm.rules}},[_c('a-form-model-item',{staticStyle:{"width":"100%"},attrs:{"label":"适用性别","prop":"gender"}},[_c('a-select',{attrs:{"placeholder":"请选择适用性别","allowClear":""},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}},[_c('a-select-option',{attrs:{"value":0}},[_vm._v(" 男性 ")]),_c('a-select-option',{attrs:{"value":1}},[_vm._v(" 女性 ")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v(" 通用 ")])],1)],1),_c('a-form-model-item',{attrs:{"label":"指数名称","prop":"indexName"}},[_c('a-input',{attrs:{"maxLength":16,"placeholder":"请输入指数名称"},model:{value:(_vm.form.indexName),callback:function ($$v) {_vm.$set(_vm.form, "indexName", $$v)},expression:"form.indexName"}})],1),_c('a-form-model-item',{attrs:{"label":"设定范围(%)","prop":"categoryName"}},[_c('div',{staticClass:"listNum",staticStyle:{"width":"100%"}},[_c('a-input',{staticStyle:{"width":"150px"},attrs:{"maxLength":16},model:{value:(_vm.form.maxPercentage),callback:function ($$v) {_vm.$set(_vm.form, "maxPercentage", $$v)},expression:"form.maxPercentage"}}),_c('a-input',{staticStyle:{"width":"150px"},attrs:{"maxLength":16},model:{value:(_vm.form.minPercentage),callback:function ($$v) {_vm.$set(_vm.form, "minPercentage", $$v)},expression:"form.minPercentage"}})],1)])],1)],1),_c('div',{style:({
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      })},[_c('a-button',{on:{"click":_vm.handleCancel}},[_vm._v("取 消")]),_c('a-button',{staticClass:"submitClass",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v("保 存")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }