<template>
  <a-drawer width="55%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item :label="$t('意见反馈.用户')"  prop="nickName" class="hide">
        <a-input v-model="form.nickName" readOnly   />
      </a-form-model-item>
      <a-form-model-item :label="$t('意见反馈.联系方式')" prop="mobile" class="hide">
        <a-input v-model="form.mobile"  />
      </a-form-model-item>
      <a-form-model-item :label="$t('意见反馈.反馈类型')" prop="location"  class="hide">
        <a-select style="width: 100%" v-model="form.type" allowClear disabled="true">
          <a-select-option v-for="(item, index) in this.customDict.FeedbackTypeEnum" :value="item.type"
                           :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :label="$t('意见反馈.反馈内容')" prop="content"  class="hide">
        <a-textarea v-model="form.content"  :auto-size="{ minRows: 3, maxRows: 5 }"  readOnly />
      </a-form-model-item>
      <a-form-model-item :label="$t('意见反馈.反馈图片')"  class="hide" prop="imgUrlList" >
        <a-upload
          name="imgUrlList"
          v-model="imgUrlList"
          listType="picture-card"
          :fileList="imgUrlList"
          class="avatar-uploader"
          :multiple="false"
          :show-upload-list="true"
          :preview="handlePreview">
        </a-upload>
      </a-form-model-item>

      <a-form-model-item :label="$t('意见反馈.处理状态')" prop="type" >
        <a-select style="width: 100%" v-model="form.processStatus">
          <a-select-option v-for="(item, index) in this.customDict.FeedbackStatusEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :label="$t('意见反馈.处理结果')" prop="processResult" >
        <a-input v-model="form.processResult" placeholder="请输入处理结果" />
      </a-form-model-item>
      <a-form-model-item label="评价" v-if="form.userEvaluation" prop="evaluation" class="hide">
        <table class="info-table">
          <tr>
            <td class="first">专业性：</td>
            <td class="data">
              {{form.userEvaluation.proStar}}
            </td>
          </tr>
          <tr>
            <td class="first">及时性：</td>
            <td class="data">
              {{form.userEvaluation.timeStar}}
            </td>
          </tr>
          <tr>
            <td class="first">服务态度：</td>
            <td class="data">
              {{form.userEvaluation.serviceStar}}
            </td>
          </tr>
          <tr>
            <td class="first">评价内容：</td>
            <td class="data">
              {{form.userEvaluation.evaluation}}
            </td>
          </tr>
        </table>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getFeedback, addFeedback, updateFeedback } from '@/api/user/feedback'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      loading: '',
      imgUrlList: [],
      // 表单参数
      form: {
        id: null,

        location: null,
        imgUrlList: [],
        event: null,

        remark: null,
        nickname :null,
        userId: null,
        type: null,

        processStatus: 0,

        processResult: null,

        imgUrls: null,

        contact: null,

        evaluation: null,

        createTime: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户id，外键{user.id}不能为空', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '反馈类型：1-意见建议、2-故障报告、3-投诉不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        location: null,
        event: null,
        remark: null,
        userId: null,
        type: null,
        processStatus: 0,
        processResult: null,
        imgUrls: null,
        contact: null,
        evaluation: null,
        createTime: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getFeedback({"id":id}).then(response => {
        this.form = response.data
        this.imgUrlList = []
        var imgUrlList = this.form.imgUrlList ==null ? [] : this.form.imgUrlList;
        for (var j = 0; j < imgUrlList.length; j++) {
          this.imgUrlList.push({status: 'done', url: imgUrlList[j], uid: this.getUidRandom(), name: 'image.jpg'})
        }

        this.open = true
        this.formTitle = this.$t('通用.按钮.修改')
      })
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateFeedback(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addFeedback(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.hide {
  /deep/ .ant-input {
    border: 0 !important;
  }
}
</style>