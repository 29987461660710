import request from '@/utils/request'


// 查询商品参数列表
export function listParam(query) {
  return request({
    url: '/goods/param/list',
    method: 'get',
    params: query
  })
}

// 查询商品参数分页
export function pageParam(query) {
  return request({
    url: '/goods/param/page',
    method: 'get',
    params: query
  })
}

// 查询商品参数详细
export function getParam(data) {
  return request({
    url: '/goods/param/detail',
    method: 'get',
    params: data
  })
}

// 新增商品参数
export function addParam(data) {
  return request({
    url: '/goods/param/add',
    method: 'post',
    data: data
  })
}

// 修改商品参数
export function updateParam(data) {
  return request({
    url: '/goods/param/edit',
    method: 'post',
    data: data
  })
}
export function statusLabel(data) {
  return request({
    url: '/goods/param/status',
    method: 'post',
    data: data
  })
}
// 删除商品参数
export function delParam(data) {
  return request({
    url: '/goods/param/delete',
    method: 'post',
    data: data
  })
}

