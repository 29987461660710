<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('意见反馈.处理状态')" prop="processStatus">
                <a-select :placeholder="$t('通用.输入.请选择')+$t('意见反馈.处理状态')" style="width: 100%" v-model="queryParam.processStatus" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.FeedbackStatusEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('意见反馈.反馈类型')" prop="type">
                <a-select :placeholder="$t('通用.输入.请选择')+$t('意见反馈.反馈类型')" style="width: 100%" v-model="queryParam.type" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.FeedbackTypeEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="processStatus" slot-scope="text, record">
           <custom-dict-tag :options="customDict.FeedbackStatusEnum" :value="record.processStatus"/>
        </span>
        <span slot="type" slot-scope="text, record">
           <custom-dict-tag :options="customDict.FeedbackTypeEnum" :value="record.type"/>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['user:feedback:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['user:feedback:edit']">
            <a-icon type="edit" />{{$t('意见反馈.处理')}}
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageFeedback,listFeedback, delFeedback } from '@/api/user/feedback'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Feedback',
  components: {
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewVisible: false,
      previewAvatar: '',
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        location: null,
        event: null,
        userId: null,
        type: undefined,
        processStatus: undefined,
        processResult: null,
        imgUrls: null,
        contact: null,
        evaluation: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
      /* {
          title: '主键ID',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: this.$t('意见反馈.用户'),
          dataIndex: 'nickName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('意见反馈.联系方式'),
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('意见反馈.反馈类型'),
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('意见反馈.反馈内容'),
          dataIndex: 'content',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('意见反馈.处理状态'),
          dataIndex: 'processStatus',
          scopedSlots: { customRender: 'processStatus' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('意见反馈.处理结果'),
          dataIndex: 'processResult',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('意见反馈.创建时间'),
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询意见反馈列表 */
    getList () {
      this.loading = true
     pageFeedback(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        location: undefined,
        event: undefined,
        userId: undefined,
        type: undefined,
        processStatus: undefined,
        processResult: undefined,
        imgUrls: undefined,
        contact: undefined,
        evaluation: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids= []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delFeedback(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                that.$t('通用.文本.删除成功'),
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/feedback/export', {
            ...that.queryParam
          }, `意见反馈_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    handleSelectExport () {
      var that = this
      let queryParam = {"idList": this.ids}
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出选中数据'),
        onOk () {
          that.download('user/feedback/export', queryParam, `意见反馈_${new Date().getTime()}.xlsx`)
          that.ids = []
          that.multiple = true;
          that.selectedRowKeys=[]
        },
        onCancel () {
          that.ids = []
          that.multiple = true;
          that.selectedRowKeys=[]
        }
      })
    }
  }
}
</script>
