<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
<!--      <a-form-model-item label="用户昵称" prop="userName" >
        <a-input v-model="form.userName"  :readOnly="readOnly" />
&lt;!&ndash;        :readOnly="readOnly"&ndash;&gt;
      </a-form-model-item>-->
      <a-form-model-item label="发票类型" prop="invoiceType" >
        <a-select style="width: 100%" v-model="form.invoiceType" allowClear :readOnly="readOnly">
          <a-select-option v-for="(item, index) in this.customDict.InvoiceTypeEnum" :value="item.type"
                           :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="抬头类型" prop="titleType" >
        <a-select style="width: 100%" v-model="form.titleType" allowClear :readOnly="readOnly">
          <a-select-option v-for="(item, index) in this.customDict.InvoiceTitleTypeEnum" :value="item.type"
                           :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="抬头名称" prop="titleName" >
        <a-input v-model="form.titleName"   />
      </a-form-model-item>
      <a-form-model-item label="联系方式" prop="contactPhone" >
        <a-input v-model="form.contactPhone"   />
      </a-form-model-item>
      <a-form-model-item label="电子邮箱" prop="mail" >
        <a-input v-model="form.mail"   />
      </a-form-model-item>
      <a-form-model-item label="公司税号" prop="companyTax" >
        <a-input v-model="form.companyTax"   />
      </a-form-model-item>
      <a-form-model-item label="注册地址" prop="companyRegisterAddress" >
        <a-input v-model="form.companyRegisterAddress"   />
      </a-form-model-item>
      <a-form-model-item label="注册电话" prop="companyRegisterPhone" >
        <a-input v-model="form.companyRegisterPhone"   />
      </a-form-model-item>
      <a-form-model-item label="开户银行" prop="companyBankName" >
        <a-input v-model="form.companyBankName"  />
      </a-form-model-item>
      <a-form-model-item label="银行账号" prop="companyBankNo" >
        <a-input v-model="form.companyBankNo"   />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark"  type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{ $t('通用.按钮.保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUser, addUser, updateUser } from '@/api/invoice/user'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      readOnly: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        invoiceType: null,

        titleType: null,

        titleName: null,

        contactPhone: null,

        mail: null,

        companyTax: null,

        companyRegisterAddress: null,

        companyRegisterPhone: null,

        companyBankName: null,

        companyBankNo: null,

        isDefault: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        invoiceType: null,
        titleType: null,
        titleName: null,
        contactPhone: null,
        mail: null,
        companyTax: null,
        companyRegisterAddress: null,
        companyRegisterPhone: null,
        companyBankName: null,
        companyBankNo: null,
        isDefault: null,
        createTime: null,
        remark: null,
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids, readOnly) {
      this.reset()
      this.formType = 2
      this.readOnly= readOnly
      const id = row ? row.id : ids
      getUser({"id": id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUser(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
